.mobileMenu {
  margin-right: var(--layout-gutter);
}

.toggleMenuButton {
  display: block;
  appearance: none;
  padding: 4px;
  border: 0;
  background: transparent;
  color: var(--navbar-text-color-secondary);
  font-size: 24px;
  cursor: pointer;

  &:hover {
    color: var(--navbar-text-color-active);
  }
}

.navigationActions {
  display: flex;
  gap: var(--space-xs);
}

.actions {
  display: flex;
  flex-flow: column wrap;
  align-items: flex-start;
  margin-top: var(--space-2xl);
  padding-top: var(--space-s);
  padding-bottom: var(--space-xl);
  border-top: 1px solid var(--color-border);
  gap: var(--space-s);
}

.secondaryNavigationOverlay,
.secondaryNavigationContainer {
  display: none;
  pointer-events: none;
}

.primaryNavigation {
  position: fixed;
  z-index: 1000;
  inset: calc(var(--nav-height) + var(--banner-height)) 0 0 0;
  height: calc(100vh - var(--nav-height));
  padding-right: var(--layout-gutter);
  padding-left: var(--layout-gutter);
  overflow-y: auto;
  transition: opacity var(--transition-fast);
  opacity: 0;
  background: var(--color-background-default);
  pointer-events: none;
  -webkit-overflow-scrolling: touch;

  &.open {
    opacity: 1;
    pointer-events: auto;
  }

  & > ul {
    display: flex;
    flex-direction: column;
    gap: var(--space-s);
    margin: 0;
    padding-top: var(--space-s);
  }
}

.mobileMenuShowing,
.secondaryNavigationShowing {
  --navbar-text-color: var(--color-text-default);
  --navbar-text-color-secondary: var(--color-text-secondary);
  --navbar-text-color-active: var(--color-accent-default);
  --color-text-default: var(--base-color-grey-900);

  background: var(--color-background-default);
}

.primaryNavigationListItem {
  display: flex;
  flex-direction: column;
}

.primaryNavigationListItem::before {
  display: none;
}

.secondaryNavigationList {
  padding: var(--space-m) 0 var(--space-l);
}

.secondaryNavigationCardSlider {
  padding-bottom: var(--space-xl);
}

.secondaryNavigationListItem + .secondaryNavigationListItem {
  margin-top: 1em;
}

.primaryNavigationLink,
.secondaryNavigationLink {
  padding: 0;
  border: 0;
  background: transparent;
  color: inherit;
  font: inherit;
  text-decoration: none;
  cursor: pointer;
}

.primaryNavigationLink {
  display: flex;
  align-items: center;
  opacity: 0.75;
  color: var(--navbar-text-color);
  font-family: var(--font-family-headline);
  font-size: var(--text-body-l);
  gap: 0.25em;

  &:hover,
  &[aria-current] {
    opacity: 1;
    color: var(--navbar-text-color-active);
  }
}

.primaryNavigationLinkText {
  border-bottom: 1px solid transparent;
}

.primaryNavigationLink[aria-current] {
  color: var(--navbar-text-color-active);
}

.primaryNavigationLink[aria-current] .primaryNavigationLinkText {
  border-bottom: 1px solid currentcolor;
}

.primaryNavigationLink[aria-current] .primaryNavigationLinkIcon {
  transform: rotate(180deg);
}

.secondaryNavigationLink {
  font-family: var(--font-family-headline);
  font-size: var(--text-headline-m);

  &:hover,
  &[aria-current] {
    color: var(--color-accent-default);
  }
}

.secondaryNavigationBadge {
  display: inline-block;
  margin-left: var(--space-s);
  padding: 0.35em 0.75em;
  border-radius: 9999px;
  background: var(--badge-color);
  color: var(--color-text-on-color);
  font-family: var(--font-family-mono);
  font-size: var(--text-body-xs);
  letter-spacing: 0.1em;
  line-height: 1;
  text-transform: uppercase;
  vertical-align: middle;
}

.secondaryNavigationSpacer {
  width: 100%;
  height: 1px;
  margin-top: var(--space-l);
  margin-bottom: var(--space-l);
  background: var(--color-border);
}

/* RESPONSIVE */
@media (--large) {
  .mobileMenu {
    display: none;
  }

  .primaryNavigation {
    display: flex;
    position: relative;
    z-index: 1000;
    inset: auto;
    justify-content: space-between;
    width: var(--nav-width);
    height: auto;
    padding: 0 var(--layout-gutter) 0 var(--nav-padding-left);
    opacity: 1;
    background: transparent;
    pointer-events: auto;
    gap: var(--space-s);

    & > ul {
      display: flex;
      flex-direction: row;
      flex-grow: 1;
      align-items: center;
      justify-content: space-between;
      margin: 0;
      padding: 0;
      overflow: hidden;
    }
  }

  .secondaryNavigation {
    position: fixed;
    z-index: 1000;
    top: calc(var(--nav-height) + var(--banner-height));
    right: 0;
    bottom: 0;
    width: var(--nav-width);
    height: var(--navbar-secondary-navigation-height);
    padding: var(--space-l) var(--layout-gutter) var(--space-3xl)
      var(--nav-padding-left);
    overflow-y: auto;
  }

  .secondaryNavigationList {
    padding: 0;
  }

  .secondaryNavigationOverlay {
    content: '';
    display: block;
    position: fixed;
    z-index: 900;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgb(0 0 0 / 75%);
    pointer-events: none;
  }

  .secondaryNavigationContainer {
    display: block;
    position: fixed;
    z-index: 900;
    top: 0;
    right: 0;
    bottom: 0;
    width: var(--nav-width);
    height: 100vh;
    background: var(--color-background-default);
  }

  .primaryNavigationLink {
    font-size: var(--text-body-m);
  }

  .actions {
    flex-direction: row;
    align-items: center;
    gap: var(--space-s-m);
    margin: 0;
    padding: 0;
    border: 0;
  }
}
