.root {
  padding: var(--space-xl-2xl) var(--layout-gutter) var(--space-xl-2xl)
    var(--layout-gutter);
  background: var(--color-background-default);
  color: var(--color-text-default);
}

.logo {
  width: 150px;
  height: auto;
  color: var(--color-text-default);
}

.navPrimary {
  font-size: var(--text-body-s);
  padding-bottom: var(--space-xl);
}

.navPrimary a,
.navSecondary a {
  color: inherit;
  font: inherit;
  text-decoration: none;

  &:hover {
    color: var(--color-accent-default);
  }
}

.navPrimaryList {
  column-gap: var(--column-gutter);
  column-width: 150px;
  max-width: 1480px;
  margin: 0 auto;
}

.navPrimaryListItem {
  margin-bottom: var(--space-m);
  font-family: var(--font-family-headline);
  break-inside: avoid-column;
}

.navSecondaryList {
  margin-top: 0.25em;
}

.navSecondaryListItem {
  color: var(--color-text-secondary);
  font-family: var(--font-family-body);
}

.navSecondaryListItem + .navSecondaryListItem {
  margin-top: 0.25em;
}

.bottomFooter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: var(--column-gutter);
  padding-top: var(--space-xl);
  border-top: 2px solid var(--color-border);
}

.logoWrapper {
  flex: 0 0 100%;
}

.socialLinks {
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 0.5em;
}

.socialLink {
  color: var(--color-text-tertiary);

  &:hover {
    color: var(--color-text-default);
  }
}

.socialLinkIcon {
  width: 2em;
  height: 2em;
}

.copyRight {
  color: var(--color-text-tertiary);
  font-size: var(--text-body-xs);
  text-align: center;
}

@media (--small) {
  .navPrimaryList {
    column-width: 220px;
  }
}

@media (--medium) {
  .logoWrapper {
    flex: 1;
  }

  .bottomFooter {
    gap: var(--column-gutter);
    justify-content: flex-start;

    & > * {
      flex: 1;
    }
  }
}
