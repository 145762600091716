.root {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: var(--card-min-height, 10em);
  padding: var(--space-s) var(--space-m);
  border: 1px solid var(--color-border);
  border-radius: var(--border-radius-small);
  background: var(--color-background-card);
  text-decoration: none;
}

.title {
  margin: 0;
  color: var(--color-text-default);
  font-family: var(--font-family-headline);
  font-size: var(--text-body-m);
  font-weight: inherit;
}

.text {
  margin-top: var(--space-2xs);
  color: var(--color-text-secondary);
}

.footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: var(--space-m);
}

.logo {
  --logo-max-width: 30px;
  --logo-max-height: 14px;

  opacity: 0.75;
  color: var(--color-text-secondary);
}

.arrowIcon {
  margin-right: 0;
  margin-left: auto;
  color: var(--color-text-secondary);
  font-size: 1.3em;
}

/* VARIATIONS */
.root.hasLink {
  cursor: pointer;
}

.root.hasLink:hover {
  border-color: var(--color-accent-default);
  background: var(--color-accent-default);
}

.root.hasLink:hover .title,
.root.hasLink:hover .text,
.root.hasLink:hover .logo,
.root.hasLink:hover .arrowIcon {
  color: var(--color-text-on-color);
}

.root.hasLink:hover .logo {
  opacity: 1;
}

@media (--medium) {
  .logo {
    --logo-max-width: 40px;
    --logo-max-height: 20px;
  }
}
