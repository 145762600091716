.root {
  padding-top: var(--space-s);
  border-top: 1px solid var(--color-border);
}

.root + .root {
  margin-top: var(--space-xl);
}

.header {
  display: flex;
  align-items: baseline;
  gap: var(--space-m);
  justify-content: space-between;
}

.cards {
  width: calc(100% + var(--layout-gutter) * 2);
  margin-top: var(--space-s);
  margin-right: calc(-1 * var(--layout-gutter));
  margin-left: calc(-1 * var(--layout-gutter));
  overflow-x: auto;
  overflow-y: hidden;
  scrollbar-width: none;
}

.cards::-webkit-scrollbar {
  display: none;
}

.cardsWrapper {
  display: flex;
  width: fit-content;
  padding-right: var(--layout-gutter);
  padding-left: var(--layout-gutter);

  gap: var(--space-s);

  & > * {
    --card-min-height: 120px;

    width: 200px;
  }
}

@media (--medium) {
  .root {
    margin-top: var(--space-xl);
  }

  .cards {
    width: 100%;
    margin-right: 0;
    margin-left: 0;
  }

  .cardsWrapper {
    width: 100%;
    padding-right: 0;
    padding-left: 0;
  }

  .cardsWrapper > * {
    width: 33%;
  }
}
