.root {
  --logo-max-width: 40px;
  --logo-max-height: 20px;

  display: block;
}

/* VARIATIONS */
.root.square {
  width: calc(var(--logo-max-width) * 0.8);
  height: auto;
}

.root.portrait {
  width: var(--logo-max-width);
  height: auto;
}

.root.landscape {
  width: auto;
  height: var(--logo-max-height);
}
