.root {
  --navbar-text-color: var(--color-text-default);
  --navbar-text-color-secondary: var(--color-text-secondary);
  --navbar-text-color-active: var(--color-accent-default);
  --navbar-secondary-navigation-height: auto;

  display: flex;
  position: fixed;
  z-index: 900;
  top: 0;
  left: 0;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: calc(100% - var(--removed-body-scroll-bar-size, 0px));
  height: calc(var(--nav-height) + var(--banner-height));
  padding-top: var(--banner-height);
  transition: transform var(--transition-default),
    background var(--transition-fast), color var(--transition-fast),
    box-shadow var(--transition-fast);
}

.root.initialThemeDark {
  --navbar-text-color: var(--color-text-on-color);
  --navbar-text-color-secondary: rgb(255 255 255 / 65%);
  --navbar-text-color-active: var(--color-text-on-color);
  --color-text-default: var(--color-text-on-color);
}

.root.mobileMenuShowing,
.root.secondaryNavigationShowing {
  --navbar-text-color: var(--color-text-default);
  --navbar-text-color-secondary: var(--color-text-secondary);
  --navbar-text-color-active: var(--color-accent-default);
  --color-text-default: var(--base-color-grey-900);

  background: var(--color-background-default);
}

.root.inStickyRange {
  --navbar-text-color: var(--color-text-default);
  --navbar-text-color-secondary: var(--color-text-secondary);
  --navbar-text-color-active: var(--color-accent-default);
  --color-text-default: var(--base-color-grey-900);

  transform: translateY(-100%);
  background: var(--color-background-default);
}

.root.inStickyRange.sticky {
  transform: translateY(0);
  box-shadow: 0 0 10px rgb(0 0 0 / 5%);
}

.logo {
  margin-right: auto;
  margin-left: var(--layout-gutter);
  color: var(--navbar-text-color);
  font: inherit;
  text-decoration: none;

  &>svg {
    width: 150px;
    height: auto;
  }
}

.logo:hover {
  opacity: 0.75;
}

/* RESPONSIVE */
@media (--large) {
  .root {
    --nav-padding-left: var(--space-xl-2xl);
    --nav-width: min(85vw, 1000px);
    --navbar-secondary-navigation-height: calc(100vh - var(--nav-height));
  }
}

@media (--xlarge) {
  .root {
    --nav-width: min(85vw, 1050px);
  }
}

@media (--max) {
  .root {
    --nav-width: 55%;
  }
}
