.root {
  --close-button-size: 32px;

  display: flex;
  align-items: center;
  position: fixed;
  z-index: var(--z-index-overlay);
  top: 0;
  left: 0;
  width: 100%;
  height: var(--banner-height);
  padding: var(--space-s-m) max(calc(var(--close-button-size) + var(--space-s-m)), var(--layout-gutter)) var(--space-s-m) var(--layout-gutter);
  background: var(--banner-background, var(--color-accent-default));
  color: var(--color-text-on-color);
  font-family: var(--font-family-headline);
  font-size: var(--text-body-m);
  text-decoration: none;
  gap: var(--space-3xs) var(--space-xs);
}

body:not(.bodyWithBanner) .root {
  display: none;
}

.content {
  display: flex;
  align-items: center;
  gap: var(--space-xs-s);
  width: 100%;
}

.image {
  position: relative;
  background: var(--color-background-inverted);
  width: calc(0.65 * var(--banner-height));
  border-radius: 9999px;
  overflow: hidden;
}

.image>img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.action {
  display: none;
  align-items: center;
  gap: 0.25em;
  opacity: 0.5;
}

.content:hover .action {
  opacity: 1;
  text-decoration: underline;
}

.close {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  width: var(--close-button-size);
  height: 100%;
  padding: 0;
  margin: 0;
  background: transparent;
  border: 0;
  color: currentColor;
  opacity: 0.5;
  cursor: pointer;
}

.close:hover {
  opacity: 1;
}

.closeIcon {
  width: 18px;
  height: 18px;
}

.bodyWithBanner {
  --banner-height: 64px;
}

/* RESPONSIVE */
@media (--small) {
  .bodyWithBanner {
    --banner-height: 50px;
  }

  .action {
    display: flex;
  }
}

@media (--large) {
  .bodyWithBanner {
    --banner-height: 56px;
  }
}
