.root {
  display: block;
  overflow: hidden;
  color: var(--color-text-default);
  text-decoration: none;
}

.imageWrapper {
  position: relative;
  width: 100%;
  aspect-ratio: 16 / 9;
  overflow: hidden;
  border-radius: var(--border-radius-default) var(--border-radius-default) 0 0;

  /* This fixes the weird overflow on scale up issue in Safari */

  clip-path: content-box;
}

.imageWrapper > * {
  transform: scale(1);
  transition: transform var(--transition-default);
  object-fit: cover;
  will-change: transform;
}

.root:hover .imageWrapper > * {
  transform: scale(1.1);
}

.meta {
  padding: var(--space-s);
  border: 1px solid var(--color-border);
  border-top: 0;
  border-radius: 0 0 var(--border-radius-default) var(--border-radius-default);
}

.root:hover .meta {
  background-color: var(--color-accent-default);
  color: var(--color-text-on-color);
}

.link {
  margin-top: var(--space-s);
  opacity: 0.75;
  font-size: var(--text-body-s);
}

.root:hover .link {
  text-decoration: underline;
  text-decoration-thickness: 1px;
}
